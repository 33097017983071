let selector = document.querySelectorAll('input[type=tel]');

var im = new Inputmask("+7 (999) 999-99-99");
im.mask(selector);

// validate forms
let validateForms = function(selector, rules, scenario) {

	new window.JustValidate(selector, {
		rules: rules,
		submitHandler: function (form, values, ajax) {
			var formData = new FormData(form);

			var xhr = new XMLHttpRequest();

			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4) {

					if (xhr.status === 200) {
						console.log('Отправлено!')
					} else {

					}
				}
			}

			$('.popup').addClass('popup--visible')
    
                xhr.open('POST', "mail_call.php", true);
				xhr.send(formData);
				form.reset();
		},
	});
}

validateForms('.call-back_form', {name: {}, tel: { required: true } }, 'call');

$(document).click(function(){
	$('.popup--visible').removeClass('popup--visible');
})

$(document).scroll(function(){
	$('.popup--visible').removeClass('popup--visible');
})

$('a[href^="#"]').on('click',function (e) {
    e.preventDefault();

    var target = $(this.hash);
    $('html, body').stop().animate({
        'scrollTop': target.offset().top
    }, 0);
});